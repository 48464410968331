<template>
  <v-col cols="12" lg="2" class="sticky-sidebar">
    <template v-if="!$vuetify.breakpoint.lgAndDown">
      <v-list class="pt-0 sticky-sidebar__item">
        <v-list-item-group
          color="primary"
          class="d-flex flex-row flex-lg-column justify-start"
        >
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/instances/${$route.params.id}/advanced/details`"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.siteDetails.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/instances/${$route.params.id}/advanced/copy-instance`"
            v-if="instance.plan_settings.copy_instance_allowed && instance.getUserPrivileges('general.copy_instance')"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.copyInstance.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="instance.getUserPrivileges('wordpress.manage_cloudflare') && instance.service_has_dns_cloudflare && instance.domain_has_dns_zone"
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/instances/${$route.params.id}/advanced/cloudflare`"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.cloudflare.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="instance.hasWpCloudServer() && instance.getUserPrivileges('wordpress.manage_edge_cache')"
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/instances/${$route.params.id}/advanced/edge-cache`"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.edge-cache.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/instances/${$route.params.id}/advanced/security`"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.security.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            :active-class="'elevation-4--before'"
            :to="`/instances/${$route.params.id}/advanced/auto-updates`"
            v-if="instance.getUserPrivileges('wordpress.manage_auto_update')"
            >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.autoUpdates.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/instances/${$route.params.id}/advanced/developer-settings`"
            v-if="instance.getUserPrivileges('wordpress.manage_debug_mode') || instance.getUserPrivileges('wordpress.manage_maintenance_mode')"
            >
            <v-list-item-content>
              <v-list-item-title class="p-2">
                {{ $t('heading.instance.advanced.developer.title') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            :active-class="'elevation-4--before'"
            :to="`/instances/${$route.params.id}/advanced/wordpress-config`"
            v-if="instance.getUserPrivileges('wordpress.manage_config')"
            >
            <v-list-item-content>
              <v-list-item-title class="p-2">
                {{ $t('heading.instance.advanced.wordpressConfig.title') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :ripple="false"
            :active-class="'elevation-4--before'"
            :to="`/instances/${$route.params.id}/advanced/wordpress-users`"
            v-if="instance.getUserPrivileges('wordpress.manage_users')"

            >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.users.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/instances/${$route.params.id}/advanced/themes`"
            v-if="instance.getUserPrivileges('wordpress.manage_themes')"
          >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.theme.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :active-class="'elevation-4--before'"
            :ripple="false"
            :to="`/instances/${$route.params.id}/advanced/logs`"
            v-if="instance.getUserPrivileges('general.view_logs')"
            >
            <v-list-item-content>
              <v-list-item-title class="p-2">{{ $t('heading.instance.advanced.logs.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
    <template v-else>
      <v-tabs
        center-active
        class="pt-0 d-flex flex-row flex-lg-column justify-start"
      >
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/details`"
        >
          <span class="p-2">{{ $t('heading.instance.advanced.siteDetails.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/copy-instance`"
          v-if="instance.plan_settings.copy_instance_allowed && instance.getUserPrivileges('general.copy_instance')"
        >
          <span class="p-2">{{ $t('heading.instance.advanced.copyInstance.title') }}</span>
        </v-tab>
        <v-tab
          v-if="instance.getUserPrivileges('wordpress.manage_cloudflare') && instance.service_has_dns_cloudflare && instance.domain_has_dns_zone"
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/cloudflare`"
        >
          <span class="p-2">{{ $t('heading.instance.advanced.cloudflare.title') }}</span>
        </v-tab>
        <v-tab
          v-if="instance.hasWpCloudServer() && instance.getUserPrivileges('wordpress.manage_edge_cache')"
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/edge-cache`"
        >
          <span class="p-2">{{ $t('heading.instance.advanced.edge-cache.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/security`"
        >
          <span class="p-2">{{ $t('heading.instance.advanced.security.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/auto-updates`"
          v-if="instance.getUserPrivileges('wordpress.manage_auto_update')"
          >
          <span class="p-2">{{ $t('heading.instance.advanced.autoUpdates.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/developer-settings`"
        >
          <span class="p-2">{{ $t('heading.instance.advanced.developer.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/wordpress-config`"
          v-if="instance.getUserPrivileges('wordpress.manage_config')"
          >
          <span class="p-2">{{ $t('heading.instance.advanced.wordpressConfig.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/wordpress-users`"
          v-if="instance.getUserPrivileges('wordpress.manage_users')"
        >
          <span class="p-2">{{ $t('heading.instance.advanced.users.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/themes`"
          v-if="instance.getUserPrivileges('wordpress.manage_themes')"
        >
          <span class="p-2">{{ $t('heading.instance.advanced.theme.title') }}</span>
        </v-tab>
        <v-tab
          :active-class="'elevation-2'"
          :ripple="false"
          :to="`/instances/${$route.params.id}/advanced/logs`"
          v-if="instance.getUserPrivileges('general.view_logs')"
          >
          <span class="p-2">{{ $t('heading.instance.advanced.logs.title') }}</span>
        </v-tab>
      </v-tabs>
    </template>
  </v-col>
</template>

<script>
export default {
   props: {
     instance: Object
   },
};
</script>

<style lang="scss" scoped>
.v-list {
  .v-list-item {
    padding-left: 0px;
    transition: padding-left 0.3s ease;
    .v-list-item__content {
      position: relative;
      z-index: 1;
    }
    .v-list-item__title {
      font-weight: $font-weight-semibold;
      color: map-get($text, header);
      transition: color 0.24s ease, font-weight 0.24s ease;
    }
    &::before {
      z-index: 0;
      background: #fff;
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    &:hover {
      padding-left: 12px;
      .v-list-item__title {
        color: map-get($primary, base);
      }
    }
    &--active {
      padding-left: 12px;
      border-radius: 4px;
      .v-list-item__title {
        font-weight: $font-weight-bold;
        color: map-get($primary, darken1);
      }
      &::before {
        border-radius: 4px;
        opacity: 1 !important;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, md)) {
    .v-list-item-group {
      justify-content: flex-start;
    }
    .v-list-item {
      padding: 0 12px 0 12px !important;
      text-align: center;
      min-width: unset !important;
    }
  }
}

.sticky-sidebar {
  position: relative;
}

.sticky-sidebar__item {
  position: sticky;
  padding-left: 0px !important;
  top: 120px;
  left: 0;
  background: unset;
}

.disable-shadows {
  .v-tabs::v-deep {
    .v-tab {
      &--active {
        filter: none !important;
        box-shadow: none !important;
        outline: 1px solid var(--v-gray-lighten3) !important;
      }
    }
  }
}

.v-tabs::v-deep {
  padding: 8px 0 !important;

  margin: 0 -24px;
  overflow: hidden !important;
  width: 100vw;
  .v-slide-group__wrapper {
    contain: none !important;
    overflow: visible !important;

    max-width: 100%;
  }
  .v-tabs-slider-wrapper {
    display: none;
  }
  .v-tab {
    position: relative;
    overflow: visible !important;
    padding: 0 16px;
    border-radius: 4px;
    box-shadow: none;
    transition: opacity 0.3s ease;
    margin-left: 4px;
    margin-right: 4px;
    background: transparent;
    span {
      font-size: $font-size-base;
      line-height: $line-height-base;
      color: map-get($text, heading);
      font-weight: $font-weight-semibold;
    }
    &:not(:first-child) {
      margin-left: 0 !important;
    }

    &--active {
      background: #fff;

      span {
        color: map-get($primary, darken1);
        font-weight: $font-weight-bold;
      }
    }
  }
}
</style>
